/* eslint-disable */
import 'lazysizes'

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}

export const shouldUpdateScroll = () => {
	return window.location.href.indexOf( '#section-job-form' ) === -1; 
}

export const onRouteUpdate = () => { 
  setTimeout(() => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({ event: `gatsby-route-change` })
  }, 50)
}