// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-success-stories-tsx": () => import("./../../../src/pages/success-stories.tsx" /* webpackChunkName: "component---src-pages-success-stories-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-job-tsx": () => import("./../../../src/templates/Job.tsx" /* webpackChunkName: "component---src-templates-job-tsx" */),
  "component---src-templates-jobs-tsx": () => import("./../../../src/templates/Jobs.tsx" /* webpackChunkName: "component---src-templates-jobs-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-success-story-tsx": () => import("./../../../src/templates/SuccessStory.tsx" /* webpackChunkName: "component---src-templates-success-story-tsx" */)
}

